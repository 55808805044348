import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6feee1de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "view main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Nav = _resolveComponent("Nav")!
  const _component_EditUserInfo = _resolveComponent("EditUserInfo")!
  const _component_Popup = _resolveComponent("Popup")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Nav, { class: "comp nav" }),
    (_ctx.showEditUserInfoPage)
      ? (_openBlock(), _createBlock(_component_EditUserInfo, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.predictPopup)
      ? (_openBlock(), _createBlock(_component_Popup, { key: 1 }))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_component_router_view, {
      key: _ctx.$route.fullPath,
      class: "router-view"
    }))
  ]))
}