
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import Join from "../components/login/Join.vue";

export default defineComponent({
  components: {
    Join
  },
  setup() {

    return {
    };
  },
});
