
import { defineComponent, ref, onMounted, nextTick } from 'vue';
import VueHighcharts from 'vue3-highcharts';
import Highcharts from 'highcharts';

export default defineComponent({
  components: { VueHighcharts },
  setup() {
    const chartRef = ref<any>(null); // 차트 참조 설정

    const options = ref({
      chart: {
        backgroundColor: '#24263A',
        type: 'line',
        // height: 'calc(100% - 300px)', // 고정된 높이
        width: null, // 부모 요소에 맞게 너비 자동 조정
      },
      title: {
        text: '',
        style: { color: 'white', fontWeight: 'bold' },
      },
      xAxis: {
        categories: ['2024.10.1', '2024.10.2', '2024.10.3', '2024.10.4', '2024.10.5'],
        labels: { style: { color: 'white' } },
      },
      yAxis: {
        title: { text: '', style: { color: 'white' } },
        labels: { style: { color: 'white' } },
        endOnTick: false,
        plotBands: [{ color: 'transparent', from: 0, to: 100 }],
      },
      plotOptions: {
        line: {
          dataLabels: { enabled: true },
          enableMouseTracking: false,
        },
      },
      legend: {
        itemStyle: {
          color: 'white', 
          fontWeight: 'bold',
        },
        itemHoverStyle: {
          color: '#FFD700',
        },
      },
      series: [
        { name: 'data1', data: [78, 17, 49, 42, 97], color: '#2e8eff'},
        { name: 'data2', data: [45, 50, 80, 60, 30], color: '#ff5733',
    },
      ],
    });

    onMounted(async () => {
      await nextTick(); // DOM이 완전히 준비된 이후 실행
      const chartInstance = chartRef.value?.chart;
      if (chartInstance) {
        chartInstance.reflow(); // 차트 크기 재조정
      }
    });

    return { options, chartRef };
  },
});
