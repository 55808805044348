
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { useTdmStore } from '@/store/tdm'
import { usePatientsStore } from '@/store/patients'
import { usePopupStore } from '@/store/popup'
// import { tdmPredict } from '@/api'

interface PatientData {
  AGE: number;
  HEIGHT: number;
  WEIGHT: number;
  SEX_TP_CD: string;
  DRST_OWH_QTY: string;
  IMND_CNT: number;
  INTERVAL: string;
  CREATININE_RSLT: number;
  VNC_RSLT: number;
  LOADING: number;
  TDM_INTERVAL: number;
}

export default defineComponent({
  components: {
  },
  props:{
  },
  setup(props, {emit}) {
    const currTab = computed(() => useTdmStore().getCurrTab);
    const loading = computed(() => useTdmStore().getLoading);

    const patientData = ref<PatientData[]>([]);
    const currPatient = computed(() => usePatientsStore().getCurrPatient);
    const editPatient = computed(() => usePatientsStore().getEditPatient);
    const predictRes = computed(() => useTdmStore().getPredictRes);
    const chartOptions = ref();

    const setLoading = (loading: number) => {
      useTdmStore().setLoading(loading);
    }

    const tdmPredict = async (): Promise<any> => {
      let checkError = false;
      // 값을 제대로 입력했을 경우 true로 return됨
      checkError = usePopupStore().checkTdmPredict();
      if(checkError){
        patientData.value = [];
        const categories = ref<string[]>([]);
        const tdmValue = ref<any[]>([]);
        
        for(let i=0; i<editPatient.value.data.length; i++) {
          categories.value.push("TDM"+(i+1));
          for(let j=0; j<editPatient.value.data[i].length; j++) {
            if(Number(editPatient.value.data[i][j].VNC_RSLT) !== 0) {
              tdmValue.value.push({y: Number(editPatient.value.data[i][j].VNC_RSLT), color: '#2e8eff'});
            }
            patientData.value.push({
              "AGE": editPatient.value.AGE,
              "HEIGHT": editPatient.value.HEIGHT,
              "WEIGHT": editPatient.value.WEIGHT,
              "SEX_TP_CD": editPatient.value.SEX_TP_CD,
              "DRST_OWH_QTY": editPatient.value.data[i][j].DRST_OWH_QTY,
              "IMND_CNT": editPatient.value.IMND_CNT,
              "INTERVAL": (editPatient.value.data[i][j].INTERVAL).toString(),
              "CREATININE_RSLT": editPatient.value.data[i][j].CREATININE_RSLT,
              "VNC_RSLT": Number(editPatient.value.data[i][j].VNC_RSLT),
              "LOADING": loading.value,
              "TDM_INTERVAL": editPatient.value.data[i][j].TDM_INTERVAL
            });
          }
        }
        await useTdmStore().predict(patientData.value);
        categories.value.push("Prediction");
        tdmValue.value.push({y: Number((predictRes.value.last_reg).toFixed(2)), color: '#FF5560'});
  
        chartOptions.value = ({
          chart: {
            backgroundColor: 'transparent',
            height: 80 + '%',
            // borderColor: '#888AA5',
            // borderWidth: 1.5,
            type: 'line'
          },
          title: {
            style: {
              color: 'white',
              fontWeight: 'bold'
            },
            text: '',
          },
          subtitle: {
          },
          legend: {
            // itemStyle: {
            //   color: 'white',
            // },
            enabled: false
          },
          xAxis: {
            categories: categories.value,
            labels: {
              style: {
                color: 'white'
              }
            }
          },
          yAxis: {
            title: {
              style: {
                color: 'white',
              },
              text: 'TDM value'
            },
            labels: {
              style: {
                color: 'white'
              }
            },
            endOnTick: false,
            plotBands: [{
              color: '#FBF2D2',
              from: 0,
              to: 15,
              label: {
                text: 'Subtherapeutic range'
              }
            },{
              color: '#DCEEF8',
              from: 15,
              to: 20,
              label: {
                text: 'Therapeutic range'
              }
            },{
              color: '#F6D9E1',
              from: 20,
              to: 99,
              label: {
                text: 'Toxin range'
              }
            }],
          },
          plotOptions: {
            line: {
              dataLabels: {
                enabled: true
              },
              enableMouseTracking: false,
              series: {
                color: '#2e8eff'
              }
            }
          },
          series: [{
            name: 'TDM',
            data: tdmValue.value,
          },
          ]
        });
        useTdmStore().setChartOptions(chartOptions.value);

      }
    }


    const tdmRecom = async (): Promise<any> => {
      patientData.value = [];
      
      console.log('editPatient.value.data.length', editPatient.value.data);
      if(editPatient.value.data[0].length > 0) {
        for(let i=0; i<editPatient.value.data.length; i++) {
          for(let j=0; j<editPatient.value.data[i].length; j++) {
            patientData.value.push({
              "AGE": editPatient.value.AGE,
              "HEIGHT": editPatient.value.HEIGHT,
              "WEIGHT": editPatient.value.WEIGHT,
              "SEX_TP_CD": editPatient.value.SEX_TP_CD,
              "DRST_OWH_QTY": editPatient.value.data[i][j].DRST_OWH_QTY,
              "IMND_CNT": editPatient.value.IMND_CNT,
              "INTERVAL": (editPatient.value.data[i][j].INTERVAL).toString(),
              "CREATININE_RSLT": editPatient.value.data[i][j].CREATININE_RSLT,
              "VNC_RSLT": Number(editPatient.value.data[i][j].VNC_RSLT),
              "LOADING": loading.value,
              "TDM_INTERVAL": editPatient.value.data[i][j].TDM_INTERVAL
            });
          }
        }
      } else {
        patientData.value.push({
          "AGE": editPatient.value.AGE,
          "HEIGHT": editPatient.value.HEIGHT,
          "WEIGHT": editPatient.value.WEIGHT,
          "SEX_TP_CD": editPatient.value.SEX_TP_CD,
          "DRST_OWH_QTY": "0",
          "IMND_CNT": editPatient.value.IMND_CNT,
          "INTERVAL": "0",
          "CREATININE_RSLT": editPatient.value.CREATININE[editPatient.value.CREATININE.length-1].CREATININE_RSLT,
          "VNC_RSLT": 0,
          "LOADING": loading.value,
          "TDM_INTERVAL": 0
        });
      }
      await useTdmStore().recommend(patientData.value);
    }
    
    onMounted(() => {
    });

    return {
      currTab,
      setLoading,
      loading,
      tdmPredict,
      predictRes,
      tdmRecom,
      chartOptions,
      editPatient
    };
  },
});
