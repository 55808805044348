
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { usePatientsStore } from '@/store/patients'
import moment from 'moment';

export default defineComponent({
  components: {
  },
  props:{
  },
  setup(props, {emit}) {
    const patientInfo = computed(() => usePatientsStore().getCurrPatient);

    onMounted(() => {
    });

    return {
      patientInfo,
      moment,
    };
  },
});
