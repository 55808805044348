import { defineStore } from 'pinia'
import { ref } from 'vue';
import { getAllUserData, getUserData, setUserData, updateUserData } from '../api';

function getSessionStorage(item: string) {
  const sessionData = sessionStorage.getItem(item);
  if (sessionData) {
    return sessionData;
  }
  return "";
}
function setSessionStorage(item: string, data: any) {
  sessionStorage.setItem(item, data);
}

export interface User {
  user_id: string,
  group: {
    group_id: number,
    level: number,
    name: string
  },
  name: string,
  number: string,
  email: string,
  division: string
}

export interface JoinUser { // 회원가입 시 사용
  passwd: string,
  data: User
}

export const useUsersStore = defineStore('users', {
  state: () => ({
    loginUser: ref<JoinUser>(),
    users: ref<User[]>(),
    showEditUserInfoPage: ref(false)
  }),
  actions: {
    async getAllUserData(): Promise<void> {
      try {
        const res = await getAllUserData();
        this.users = res.Users;
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    },
    async getUserData(): Promise<void> {
      try {
        const res = await getUserData();
        setSessionStorage('user_data', res);
        this.loginUser = {
          data: res,
          passwd: getSessionStorage('user_passwd')
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    },
    saveUserData(value:JoinUser){
      this.loginUser = value;
    },
    removeUserData() {
      this.loginUser = {
        passwd: "",
        data: {
          user_id: "",
          group: {
            group_id: 1,
            level: 1,
            name: ""
          },
          name: "",
          number: "",
          email: "",
          division: ""
        }
      }
    },
    setUserInfoPageOpen(open: boolean) {
      this.showEditUserInfoPage = open;
    }
  },
  getters: {
    getLoginUser(state): any {
      return state.loginUser;
    },
    getUsers(state): any {
      return state.users;
    },
    getShowEditUserInfoPage(state): any {
      return state.showEditUserInfoPage;
    }
  },
});