
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { usePageStore } from '@/store/pagenation'
import { usePatientsStore } from '@/store/patients'

export default defineComponent({
  components: {
  },
  props:{
  },
  setup(props, {emit}) {
    const totalPage = computed(() => usePageStore().getTotalPage);
    const currPage = computed(() => usePageStore().getCurrPage);
    // const patients = computed(() => usePatientsStore().getPatients);

    const movePage = (idx: number) => {
      if(idx < 1) { idx = 1; }
      else if(idx > totalPage.value) { idx = totalPage.value; }
      usePageStore().setCurrPage(idx);
    }

    onMounted(() => {
    })

    return {
      totalPage,
      currPage,
      movePage
    };
  },
});
