
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { usePatientsStore } from '@/store/patients'
import moment from 'moment';
import lodash from 'lodash';
import 'v-calendar/style.css';
import "@/css/style.css";

export default defineComponent({
  components: {
  },
  props:{
    modelValue: {
      type: [Number, String],
      default: ''
    },
    isEditable: {
      type: Boolean,
      default: true
    }
  },
  setup(props, {emit}) {
    const patientInfo = computed(() => usePatientsStore().getCurrPatient);
    const editPatient = computed(() => usePatientsStore().getEditPatient);

    const masks = ref({
      inputDateTime24hr: 'YYYY-MM-DD HH:mm',
    });

    const focusIn = (i: any, j: any) => {
      editPatient.value.data[i][j].DRST_OWH_QTY = (editPatient.value.data[i][j].DRST_OWH_QTY).replace('mg', '')
    }
    const focusOut = (i: any, j: any) => {
      const unit = 'mg';
      editPatient.value.data[i][j].DRST_OWH_QTY = editPatient.value.data[i][j].DRST_OWH_QTY + unit;
    }

    const addItem = () => {
      usePatientsStore().addEditPatient();
    }

    const removeItem = (i: number, j: number) => {
      usePatientsStore().deleteEditPatient(i, j);
    }

    const updateInterval = (event: any, physic: any, isLastItem: boolean) => {
      const value = event.target.value;
      if (isLastItem) {
        physic.INTERVAL = '-';
      } else {
        physic.INTERVAL = value === '-' ? 0 : Number(value);
      }
    }

     const localValue = ref<string>(props.modelValue?.toString() || '');

    // modelValue가 변경되면 localValue도 업데이트
    watch(() => props.modelValue, (newValue) => {
      localValue.value = newValue?.toString() || '';
    });

    // 숫자만 허용하도록 필터링하는 메서드
    const filterNumericInput = (event: Event) => {
      const input = event.target as HTMLInputElement;
      // 숫자만 남기고 나머지 제거
      input.value = input.value.replace(/[^0-9]/g, '');
      localValue.value = input.value;
      emit('update:modelValue', input.value);
    };

    // localValue를 반영하여 최종 값 설정
    watch(localValue, (newValue) => {
      emit('update:modelValue', newValue);
    });

    // isEditable을 computed로 변환하여 사용
    const computedIsEditable = computed(() => props.isEditable);

    watch(() => patientInfo.value, (newVal) => {
      usePatientsStore().setEditPatient(lodash.cloneDeep(newVal));
    });

    onMounted(() => {
      usePatientsStore().setEditPatient(lodash.cloneDeep(patientInfo.value));
    });

    return {
      patientInfo,
      editPatient,
      moment,
      focusIn,
      focusOut,
      addItem,
      masks,
      updateInterval,
      removeItem,
      localValue,
      filterNumericInput,
      computedIsEditable
    };
  },
});
