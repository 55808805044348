
import { defineComponent, ref, computed, onMounted, watch, compile } from 'vue';
import PatientList from "@/components/tdm/PatientList.vue";
import Pagenation from "@/components/Pagenation.vue";
import { useUsersStore } from '@/store/users'

export default defineComponent({
  components: {
    PatientList,
    Pagenation,
  },
  setup() {
    const showEditUserInfoPage = computed(() => useUsersStore().getShowEditUserInfoPage);
    const showCloseBtn = ref(false);
    const closeUserInfo = ref(false);

    onMounted(() => {
      //
    });

    return {
      showCloseBtn,
      closeUserInfo,
      showEditUserInfoPage
    };
  },
});
