import { defineStore } from 'pinia'
import { ref } from 'vue';
import { tdmPredict, tdmRecommend } from '../api';

export const useTdmStore = defineStore('tdm', {
  state: () => ({
    currTab: ref('predict'),
    loading: ref(0), //로딩여부
    predictData: ref(),
    predictRes: ref(""),
    recomData: ref(),
    recomRes: ref(""),
    chartOptions: ref({})
  }),
  actions: {
    setCurrTab(tab: string) {
      this.currTab = tab;
    },
    setLoading(loading: number) {
      this.loading = loading;
    },
    async predict(data: any): Promise<void> {
      this.predictData = data;
      try {
        const res = await tdmPredict();
        this.predictRes = res;
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    },
    async recommend(data: any): Promise<void> {
      this.recomData = data;
      try {
        const res = await tdmRecommend();
        this.recomRes = res;
        console.log('recom : ', res);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    },
    initRes() {
      this.predictRes = "";
      this.recomRes = "";
      this.loading = 0;
    },
    setChartOptions(options: any) {
      this.chartOptions = options;
    },
  },
  getters: {
    getCurrTab(state): any {
      return state.currTab;
    },
    getLoading(state): any {
      return state.loading;
    },
    getPredictData(state): any {
      return state.predictData;
    },
    getPredictRes(state): any {
      return state.predictRes;
    },
    getRecomData(state): any {
      return state.recomData;
    },
    getRecomRes(state): any {
      return state.recomRes;
    },
    getChartOptions(state): any {
      return state.chartOptions;
    },
  },
});