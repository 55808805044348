import { defineStore } from 'pinia'
import { ref } from 'vue';

export const useMenuStore = defineStore('menu', {
  state: () => ({
    menuList: ref([
      { menu: 'TDM', name: 'Tdm', active: true },
      { menu: '실시간 모니터링', name: 'Monitoring', active: false },
      // { menu: '기계환기', name: 'Mv', active: false },
    ]),
    currMenu: ref()
  }),
  actions: {
    setCurrMenu(idx: number) {
      for(let i=0; i<this.menuList.length; i++) {
        this.menuList[i].active = false;
      }
      this.menuList[idx].active = true;
    }
  },
  getters: {
    getMenuList(state): any {
      return state.menuList;
    },
  },
});