import { defineStore } from 'pinia'
import { getLanguageData } from '../api';

function setSessionStorage(locale: string) {
  const localeInfo = JSON.stringify(locale);
  sessionStorage.setItem('localeInfo', localeInfo);
}

function getSessionStorage(): string | null {
  const localeInfo = sessionStorage.getItem('localeInfo');
  if (localeInfo) {
    return JSON.parse(localeInfo);
  }
  return null;
}

// interface Language { // language 형식
//   pageId: [
//     textId: {ko: '', en: ''}
//   ]
// }

interface LocaleState {
  locale: string;
  language: any;
  checkLanguage: boolean;
}

// 한영 전환
export const useLocaleStore = defineStore('locale', {
  state: (): LocaleState => ({
    locale: getSessionStorage() || "ko", // 초기 세팅 - 한국어(ko)
    language: [],
    checkLanguage: false,
  }),
  actions: {
    changeLocale(locale: string) {
      this.locale = locale;
      setSessionStorage(locale);
    },
    async getLanguageData() {
      try {
        const res = await getLanguageData();
        this.checkLanguage = true;
        this.language = res;
      } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
      }
    },
  },
  getters: {
    getLocale(state) {
      return state.locale;
    },
    getLanguage(state) {
      return state.language;
    },
    getCheckLanguage(state) {
      return state.checkLanguage;
    }
  },
});