import { defineStore } from 'pinia'
import { ref } from 'vue';

export const usePageStore = defineStore('pagenation', {
  state: () => ({
    totlaPage: ref<number>(10),
    currPage: ref<number>(1)
  }),
  actions: {
    setTotalPage(page: number) {
      this.totlaPage = page;
    },
    setCurrPage(page: number) {
      this.currPage = page;
    },
    initPage() {
      this.currPage = 1;
    }
  },
  getters: {
    getTotalPage(state): any {
      return state.totlaPage;
    },
    getCurrPage(state): any {
      return state.currPage;
    }
  },
});