
import { defineComponent, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
// import { useLocaleStore } from '@/store/locale';
import Login from "../components/login/Login.vue";
// import SelectLanguage from "../components/common/SelectLanguage.vue";

export default defineComponent({
  components: {
    Login,
    // SelectLanguage
  },
  setup() {
    // const localeStore = useLocaleStore();
    // const locale = computed(() => localeStore.getLocale);
    // const language = computed(() => localeStore.getLanguage);

    const router = useRouter();
    const goToAboutPage = (page: string) => {
      router.push({'name': page});
    };

    onMounted(() => {
    });

    return {
      // locale,
      // language,
      goToAboutPage
    };
  },
});
