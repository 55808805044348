
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useUsersStore, JoinUser } from '@/store/users'
import { updateUserData } from '@/api';
import lodash from 'lodash';

export default defineComponent({
  components: {
  },
  props:{
  },
  setup(props, {emit}) {
    const loginUser = computed(() => useUsersStore().getLoginUser);
    let userData = ref<JoinUser>(lodash.cloneDeep(loginUser.value));

    const updateUser = async (): Promise<void> => {
      const res = await updateUserData(userData.value);
      if(res.result == "Success") {
        useUsersStore().saveUserData(userData.value); 
        userData = ref<JoinUser>(lodash.cloneDeep(loginUser.value));
        close();
      }
    }

    const close = () => {
      useUsersStore().setUserInfoPageOpen(false);
    }

    onMounted(() => {
      //
    })

    return {
      userData,
      updateUser,
      close
    };
  },
});
