
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { usePatientsStore } from '@/store/patients'
import { useTdmStore } from '@/store/tdm'
import { useRouter } from 'vue-router';
import lodash from 'lodash';

export default defineComponent({
  components: {
  },
  props:{
  },
  setup(props, {emit}) {
    const router = useRouter();
    const patients = computed(() => usePatientsStore().getFilteredPatients);

    const movePage = (page: string) => {
      router.push({name: page});
    }

    const setPatientData = (patient: any) => {
      if(patient.CREATININE.length > 0){
        useTdmStore().initRes();
        for(let i=0; i<patients.value.length; i++) {
          if(patients.value[i].PT_NO === patient.PT_NO) {
            usePatientsStore().setCurrPatient(lodash.cloneDeep(patients.value[i]));
          }
        }
      }
    }

    watch(() => patients.value, (newVal) => {
      // if(patients.value.length > 0) {
      //   for(let i=0; i<patients.value.length; i++) {
      //     if(patients.value[i].CREATININE.length>0) {
      //       usePatientsStore().setCurrPatient(lodash.cloneDeep(patients.value[i]));
      //       break;
      //     }
      //   }
      // }
      usePatientsStore().initCurrPatient();
    });
    
    onMounted(() => {
    });

    return {
      patients,
      setPatientData,
      movePage
    };
  },
});
