import { defineStore } from 'pinia'
import { computed, ref } from 'vue';
import { usePatientsStore } from './patients';

export const usePopupStore = defineStore('popup', {
  state: () => ({
    isOpen: ref(false),
    tdmPrError: ref(false),
  }),
  actions: {
    togglePopup(opened: boolean) {
      this.isOpen = opened;
    },
    checkTdmPredict(){
      this.tdmPrError = false;
      const editPatient = usePatientsStore().getEditPatient.data;
      const checkArray = editPatient[editPatient.length - 1]

      // 추가한 값이 있는지 확인
      const notAdd = checkArray.some(item => Number(item.VNC_RSLT) > 0);
      if(notAdd){
        this.tdmPrError = true;
        return false;
      }

      // 행을 하나 추가했다 삭제한 경우, 새 배열이 이미 들어가있으므로, 해당 배열에 값이 존재하는지 아닌지 확인
      if(checkArray[0] == undefined){
        this.tdmPrError = true;
        return false;
      }

      // 추가한 값 중 빈 값이 있는지 확인
      const isBlank = checkArray.some((item, index) => 
        (index < checkArray.length - 1 && Number(item.INTERVAL) === 0) || Number(item.DRST_OWH_QTY) === 0 || item.ACT_TIME === ""
      );
      // const isBlank = checkArray.some(item => Number(item.DRST_OWH_QTY) === 0 || item.ACT_TIME === "");
      if(isBlank){
        this.tdmPrError = true;
        return false;
      }

      // 위에서 false를 반환하지 않았다면, 예측이 가능한 것이므로 true를 반환
      return true;
    },
    setTdmPredict(value: boolean){
      this.tdmPrError = value;
    }
  },
  getters: {
    getIsOpen(state): any {
      return state.isOpen;
    },
    getTdmPredict(state): any{
      return state.tdmPrError;
    }
  },
});