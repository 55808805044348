import { defineStore } from 'pinia'
import { ref } from 'vue';

export interface ICU {
  checked: boolean,
  name: string,
  icu: string,
}

export const useIcuStore = defineStore('icu', {
  state: () => ({
    icuList: ref<ICU[]>([
      { checked: true, name: '심혈관계중환자실(CCU)', icu: 'CCU' },
      { checked: true, name: '신경계 중환자실(NCU)', icu: 'NCU' },
      { checked: true, name: '내과중환자실(MICU)', icu: 'MICU' },
      { checked: true, name: '신생아중환자실(NICU)', icu: 'NICU' },
      { checked: true, name: '소아중환자실(PICU)', icu: 'PICU' },
      { checked: true, name: '응급중환자실(EICU)', icu: 'EICU' },
    ]),
    isOpen: ref(false),
  }),
  actions: {
    setIcu(icu: any) {
      this.icuList = icu;
    },
    togglePopup(opened: boolean) {
      this.isOpen = opened;
    }
  },
  getters: {
    getIcuList(state): any {
      return state.icuList;
    },
    getIsOpen(state): any {
      return state.isOpen;
    },
  },
});