import { defineStore } from 'pinia'
import { login, logout } from '../api';

function getAccessToken(): string {
  const accessToken = sessionStorage.getItem('access_token');
  if (accessToken !== null && accessToken !== undefined) {
    return accessToken;
  }
  return "";
}
function setSessionStorage(item: string, data: any) {
  sessionStorage.setItem(item, data);
}
function removeSessionStorage(item: string) {
  sessionStorage.removeItem(item);
}


// 현재 로그인 되어있는 유저의 정보를 저장
export const useLoginStore = defineStore('login', {
  state: () => ({
    token: getAccessToken()
  }),
  actions: {
    async login(inputId: string, inputPw: string): Promise<boolean> {
      try {
        const res = await login(inputId, inputPw);
        if (res?.access_token) {
          this.token = res.access_token;
          console.log('token >> ', res.access_token);
        }
        setSessionStorage('access_token', res.access_token);
        setSessionStorage('user_passwd', inputPw);
        return true;
      } catch (error) {
        console.error('Error fetching users:', error);
      }
      return false;
    },
    async logout() { // logout 
      const res = await logout();
      this.token = "";
      sessionStorage.clear();
      return res.result;
    },
  },
  getters: {
    getAccessToken(state): string {
      return state.token;
    }
  },
});