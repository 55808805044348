
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import Logo from "../components/Logo.vue";
import Menu from "../components/Menu.vue";
import UserInfo from "../components/UserInfo.vue";

export default defineComponent({
  components: {
    Logo,
    Menu,
    UserInfo
  },
  props:{
  },
  setup(props, {emit}) {

    onMounted(() => {
      //
    })

    return {
    };
  },
});
