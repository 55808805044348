import { defineStore } from 'pinia';
import { ref } from 'vue';
import { readPatientData } from '../api';
import { useIcuStore, ICU } from '@/store/icu';
import moment from 'moment';

interface DataItem {
  CREATININE_DTE: string;
  CREATININE_RSLT: number;
  DRST_OWH_QTY: string;
  INTERVAL: string;
  ACT_TIME: string;
  ORD_NM: string;
  VNC_DTE: string;
  VNC_RSLT: string;
  ADD: boolean;
  TDM_INTERVAL: number;
}

interface Patient {
  PT_NO: string;
  PT_NM: string;
  WD_DEPT_CD: string;
  AGE: number;
  HEIGHT: number;
  WEIGHT: number;
  SEX_TP_CD: string;
  IMND_CNT: number;
  data: Array<Array<DataItem>>;
  CREATININE: Array<DataItem>;
  VNC: Array<DataItem>;
}

export const usePatientsStore = defineStore('patients', {
  state: () => {
    const initData: Patient = {
      PT_NO: '',
      PT_NM: '',
      WD_DEPT_CD: '',
      AGE: 0,
      HEIGHT: 0,
      WEIGHT: 0,
      SEX_TP_CD: '',
      IMND_CNT: 0,
      data: [[]] as Array<Array<DataItem>>,
      CREATININE: [],
      VNC: []
    };

    return {
      initData,
      patients: ref<Patient[]>([]),
      filteredPatients: ref<Patient[]>([]),
      currPatient: { ...initData },
      editPatient: { ...initData }
    };
  },
  actions: {
    async getPatientData(): Promise<void> {
      try {
        const res = await readPatientData();
        this.patients = res.map((item:any) => ({
          ...item,
          data: item.data.map((innerArray:any) => 
            innerArray.map((dataItem:any) => ({
              ...dataItem,
              ADD: false,
            }))
          )
        }));
        
        // this.patients = res.map((item: any) => ({
        //   data: item.data.map((innerArray: any) =>
        //     innerArray.map((dataItem: any) => {
        //       console.log('item >> ', item);  // 로그를 찍기
        //       console.log('dataItem >> ', dataItem);  // 로그를 찍기
        //       return dataItem;        // dataItem을 반환
        //     })
        //   )
        // }));

        this.setFilteredPatients();
      } catch (error) {
        console.error('Error fetching patients:', error);
      }
    },
    setFilteredPatients() {
      const icuStore = useIcuStore();
      const icuCodes = icuStore.getIcuList
        .filter((icu: ICU) => icu.checked)
        .map((icu: ICU) => icu.icu);

      this.filteredPatients = this.patients.filter(patient => {
        return icuCodes.includes(patient.WD_DEPT_CD);
      });
    },
    setCurrPatient(patient: Patient) {
      this.currPatient = { ...patient };
    },
    setEditPatient(patient: Patient) {
      this.editPatient = { ...patient };
    },
    addEditPatient() {
      // 배열의 마지막 값이 ADD : true 이면 그냥 추가
      // VMC_DTE랑 비교했을때 TDM 검사를 진행하지 않았으면 그냥 추가
      // TDM 검사를 진행했고, 아직 새로 배열을 추가하지 않았으면 새로 배열을 추가

      let checkArray = this.editPatient.data[this.editPatient.data.length -1];
      let checkLastArray = checkArray[checkArray.length -1];

      if(checkArray.length !== 0){
        if(!checkLastArray.ADD){
          let lastCurr = this.currPatient.data[this.currPatient.data.length -1];
          let lastVNC = new Date(this.editPatient.VNC[this.editPatient.VNC.length -1].VNC_DTE);
          let ordDate = new Date(lastCurr[lastCurr.length -1].ACT_TIME);
  
          if((lastVNC.getTime() - ordDate.getTime() > 0) && this.currPatient.data.length == this.editPatient.data.length){
            this.editPatient.data.push([]);
          }
        }
      }

      const lastArray = this.editPatient.data[this.editPatient.data.length - 1];
      const date = ref(new Date());
      const yesterday = new Date(date.value);
      yesterday.setDate(date.value.getDate() - 1);
      
      if(lastArray.length > 0) {
        date.value = new Date(lastArray[lastArray.length-1].ACT_TIME);
      } else {
        const array = this.editPatient.data[this.editPatient.data.length - 2];
        if(array) {
          date.value = new Date(array[array.length-1].ACT_TIME);
        } else {
          date.value = yesterday;
        }
      }

      const specificDate = new Date(date.value);
      const nextDay = new Date(specificDate);
      nextDay.setDate(specificDate.getDate() + 1);

      if (Array.isArray(lastArray)) {
        lastArray.push({
          CREATININE_DTE: "",
          CREATININE_RSLT: Number(this.editPatient.CREATININE[this.editPatient.CREATININE.length-1].CREATININE_RSLT),
          DRST_OWH_QTY: "0",
          INTERVAL: "0",
          ACT_TIME: moment(nextDay).format('YYYY-MM-DD HH:mm'),
          ORD_NM: "",
          VNC_DTE: "",
          VNC_RSLT: "",
          ADD: true,
          TDM_INTERVAL: 0
        });
      }
    },
    deleteEditPatient(i: number, j: number) {
      if (this.editPatient.data[i] && this.editPatient.data[i][j]) {
        this.editPatient.data[i].splice(j, 1);
      }
    },
    initCurrPatient() {
      this.currPatient = { ...this.initData };
    }
  },
  getters: {
    getPatients(state): Patient[] {
      return state.patients;
    },
    getFilteredPatients(state): Patient[] {
      return state.filteredPatients;
    },
    getCurrPatient(state): Patient {
      return state.currPatient;
    },
    getEditPatient(state): Patient {
      return state.editPatient;
    }
  }
});