
import { defineComponent, ref, computed, onMounted, watch, compile } from 'vue';
import { useRouter } from 'vue-router';
import { useLoginStore } from '@/store/login'
import { useUsersStore } from '@/store/users'

export default defineComponent({
  components: {
  },
  props:{
  },
  setup(props, {emit}) {
    const router = useRouter();
    const loginUser = computed(() => useUsersStore().getLoginUser);
    const accessToken = computed(() => useLoginStore().getAccessToken);
    const openUserInfo = ref(false);
    

    const logout = async (): Promise<any> => {
      const res = await useLoginStore().logout();
      if(res === "Success") {
        router.push({ name: 'login' });
        useUsersStore().removeUserData();
      } else {
        console.log('LOGOUT FAIL');
      }
    }

    const openUserInfoPage = () => {
      openUserInfo.value = false;
      useUsersStore().setUserInfoPageOpen(true);
    }

    onMounted(() => {
      useUsersStore().getUserData();
    })

    return {
      logout,
      loginUser,
      accessToken,
      openUserInfo,
      openUserInfoPage
    };
  },
});
