
import { defineComponent, ref, computed, onMounted, watch, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { usePatientsStore } from '@/store/patients'
import { useIcuStore } from '@/store/icu'
import { useTdmStore } from '@/store/tdm'
import { usePageStore } from '@/store/pagenation'
import ICUBox from "../ICUBox.vue";
import lodash from 'lodash';
import moment from 'moment';

export default defineComponent({
  components: {
    ICUBox
  },
  props:{
  },
  setup(props, {emit}) {
    const router = useRouter();
    const currPage = computed(() => usePageStore().getCurrPage);
    const patients = computed(() => usePatientsStore().getFilteredPatients); // 전체 환자 리스트
    const searchRes = ref<any>([]); // 검색 결과에 맞는 환자 리스트
    const pageList = ref<any>([]); // 페이지에 보여지는 환자 리스트
    const searchText = ref("");

    const movePage = () => {
      router.push({name: 'MonitoringInfo'});
    }

    const setTotalPage = () => {
      usePageStore().setTotalPage(Math.ceil((searchRes.value.length)/16));
    }

    const setPatientData = (patient: any) => {
      if(patient.CREATININE.length > 0) {
        useTdmStore().initRes();
        for(let i=0; i<patients.value.length; i++) {
          if(patients.value[i].PT_NO === patient.PT_NO) {
            usePatientsStore().setCurrPatient(lodash.cloneDeep(patients.value[i]));
            movePage();
          }
        }
      }
    }

    const setPage = () => {
      pageList.value = [];
      for(let i=(currPage.value-1)*16; i<currPage.value*16; i++) {
        if(searchRes.value[i]){
          pageList.value.push(searchRes.value[i]);
        }
      }
      setTotalPage();
    }

    const searchPatient = () => {
      searchRes.value = [];
      if(searchText.value == "") {
        searchRes.value = patients.value;
        setPage();
      } else {
        for(let i=0; i<patients.value.length; i++) {
          let log = patients.value[i];
          let flag = false;

          let inputData = {
            WD_DEPT_CD: log.WD_DEPT_CD,
            PT_NO: log.PT_NO,
            PT_NM: log.PT_NM,
            AGE: log.AGE,
            HEIGHT: log.HEIGHT,
            WEIGHT: log.WEIGHT,
            SEX_TP_CD: log.SEX_TP_CD,
            CREATININE: log.CREATININE,
            VNC: log.VNC
          }

          if(log["PT_NO"].toString().indexOf(searchText.value) !== -1) {
            flag = true;
            let replaceText = "";
            let index = log["PT_NO"].toString().indexOf(searchText.value);
            let originText = log["PT_NO"].toString();
            replaceText = originText.substr(index, searchText.value.length);
          }
          if(log["PT_NM"].toString().indexOf(searchText.value) !== -1) {
            flag = true;
            let replaceText = "";
            let index = log["PT_NM"].toString().indexOf(searchText.value);
            let originText = log["PT_NM"].toString();
            replaceText = originText.substr(index, searchText.value.length);
          }

          if(flag){
            searchRes.value.push(inputData);
          }
        }
        setPage();
        usePageStore().initPage();
      }
    }

    const currSort = ref<any>("")

    const sortPatient = (value:any) => {
      function resetSort(){
        searchRes.value.sort(function(a:any,b:any){
          return a.PT_NO < b.PT_NO ? -1 : a.PT_NO > b.PT_NO ? 1 : 0;
        })
      }

      switch(value){
        case 'unit':
          // 내림차순이면, 한 번 이미 눌렀다는 뜻으로, 두번 누르면 오름차순 정렬
          if(currSort.value == 'unitDesc'){
            currSort.value = 'unitAsc'
            searchRes.value.sort(function(a:any,b:any){
              return a.WD_DEPT_CD < b.WD_DEPT_CD ? -1 : a.WD_DEPT_CD > b.WD_DEPT_CD ? 1 : 0;
            })
          }
          // 오름차순이면, 두 번 이미 눌렀다는 뜻으로, 세번 누르면 초기화
          else if(currSort.value == 'unitAsc'){
            currSort.value = ''
            resetSort();
          }
          // 처음 눌렀을때 내림차순 정렬
          else{
            currSort.value = 'unitDesc'
            searchRes.value.sort(function(a:any,b:any){
              return a.WD_DEPT_CD > b.WD_DEPT_CD ? -1 : a.WD_DEPT_CD < b.WD_DEPT_CD ? 1 : 0;
            })
          }
          break;
        case 'number': 
          if(currSort.value == 'numberDesc'){
            currSort.value = 'numberAsc'
            searchRes.value.sort(function(a:any,b:any){
              return b.PT_NO - a.PT_NO;
            })
          }
          else if(currSort.value == 'numberAsc'){
            currSort.value = ''
            resetSort();
          }
          else{
            currSort.value = 'numberDesc'
            searchRes.value.sort(function(a:any,b:any){
              return a.PT_NO - b.PT_NO;
            })
          }
          break;
        case 'name': 
          if(currSort.value == 'nameDesc'){
            currSort.value = 'nameAsc'
            searchRes.value.sort(function(a:any,b:any){
              return a.PT_NM < b.PT_NM ? -1 : a.PT_NM > b.PT_NM ? 1 : 0;
            })
          }
          else if(currSort.value == 'nameAsc'){
            currSort.value = ''
            resetSort();
          }
          else{
            currSort.value = 'nameDesc'
            searchRes.value.sort(function(a:any,b:any){
              return a.PT_NM > b.PT_NM ? -1 : a.PT_NM < b.PT_NM ? 1 : 0;
            })
          }
          break;
        case 'age': 
          if(currSort.value == 'ageDesc'){
            currSort.value = 'ageAsc'
            searchRes.value.sort(function(a:any,b:any){
              return b.AGE - a.AGE ;
            })
          }
          else if(currSort.value == 'ageAsc'){
            currSort.value = ''
            resetSort();
          }
          else{
            currSort.value = 'ageDesc'
            searchRes.value.sort(function(a:any,b:any){
              return a.AGE - b.AGE;
            })
          }
          break;
        case 'gender': 
          if(currSort.value == 'genderDesc'){
            currSort.value = 'genderAsc'
            searchRes.value.sort(function(a:any,b:any){
              return a.SEX_TP_CD < b.SEX_TP_CD ? -1 : a.SEX_TP_CD > b.SEX_TP_CD ? 1 : 0;
            })
          }
          else if(currSort.value == 'genderAsc'){
            currSort.value = ''
            resetSort();
          }
          else{
            currSort.value = 'genderDesc'
            searchRes.value.sort(function(a:any,b:any){
              return a.SEX_TP_CD > b.SEX_TP_CD ? -1 : a.SEX_TP_CD < b.SEX_TP_CD ? 1 : 0;
            })
          }
          break;
        case 'tdmDate': 
          if(currSort.value == 'tdmDateDesc'){
            currSort.value = 'tdmDateAsc'
            searchRes.value.sort(function(a:any,b:any){
              const aDate = a.VNC.length === 0 ? '-' : new Date(a.VNC[a.VNC.length-1].VNC_DTE);
              const bDate = b.VNC.length === 0 ? '-' : new Date(b.VNC[b.VNC.length -1].VNC_DTE);

              if (aDate === '-') return 1;
              if (bDate === '-') return -1;

              // 날짜 오름차순으로 정렬
              return aDate.getTime() - bDate.getTime();
            })
          }
          else if(currSort.value == 'tdmDateAsc'){
            currSort.value = ''
            resetSort();
          }
          else{
            currSort.value = 'tdmDateDesc'
            searchRes.value.sort(function(a:any,b:any){
              const aDate = a.VNC.length === 0 ? '-' : new Date(a.VNC[a.VNC.length-1].VNC_DTE);
              const bDate = b.VNC.length === 0 ? '-' : new Date(b.VNC[b.VNC.length -1].VNC_DTE);

              if (aDate === '-') return 1;
              if (bDate === '-') return -1;

              // 날짜 내림차순으로 정렬 (getTime()을 사용하여 숫자형으로 변환)
              return bDate.getTime() - aDate.getTime();
            })
          }
          break;
        case 'tdmCount': 
          if(currSort.value == 'tdmCountDesc'){
            currSort.value = 'tdmCountAsc'
            searchRes.value.sort(function(a:any,b:any){
              const aCount = a.VNC.length === 0 ? '-' : a.VNC[a.VNC.length-1].VNC_RSLT;
              const bCount = b.VNC.length === 0 ? '-' : b.VNC[b.VNC.length -1].VNC_RSLT;

              if (aCount === '-') return 1;
              if (bCount === '-') return -1;

              return aCount - bCount;
            })
          }
          else if(currSort.value == 'tdmCountAsc'){
            currSort.value = ''
            resetSort();
          }
          else{
            currSort.value = 'tdmCountDesc'
            searchRes.value.sort(function(a:any,b:any){
              const aCount = a.VNC.length === 0 ? '-' : a.VNC[a.VNC.length-1].VNC_RSLT;
              const bCount = b.VNC.length === 0 ? '-' : b.VNC[b.VNC.length -1].VNC_RSLT;

              if (aCount === '-') return 1;
              if (bCount === '-') return -1;

              return bCount - aCount;
            })
          }
          break;
        case 'crDate': 
          if(currSort.value == 'crDateDesc'){
            currSort.value = 'crDateAsc'
            searchRes.value.sort(function(a:any,b:any){
              const aDate = a.CREATININE.length === 0 ? '-' : new Date(a.CREATININE[a.CREATININE.length-1].CREATININE_DTE);
              const bDate = b.CREATININE.length === 0 ? '-' : new Date(b.CREATININE[b.CREATININE.length -1].CREATININE_DTE);

              if (aDate === '-') return 1;
              if (bDate === '-') return -1;

              return aDate.getTime() - bDate.getTime();
            })
          }
          else if(currSort.value == 'crDateAsc'){
            currSort.value = ''
            resetSort();
          }
          else{
            currSort.value = 'crDateDesc'
            searchRes.value.sort(function(a:any,b:any){
              const aDate = a.CREATININE.length === 0 ? '-' : new Date(a.CREATININE[a.CREATININE.length-1].CREATININE_DTE);
              const bDate = b.CREATININE.length === 0 ? '-' : new Date(b.CREATININE[b.CREATININE.length -1].CREATININE_DTE);

              if (aDate === '-') return 1;
              if (bDate === '-') return -1;

              return bDate.getTime() - aDate.getTime();
            })
          }
          break;
        case 'crCount': 
          if(currSort.value == 'crCountDesc'){
            currSort.value = 'crCountAsc'
            searchRes.value.sort(function(a:any,b:any){
              const aCount = a.CREATININE.length === 0 ? '-' : a.CREATININE[a.CREATININE.length-1].CREATININE_RSLT;
              const bCount = b.CREATININE.length === 0 ? '-' : b.CREATININE[b.CREATININE.length -1].CREATININE_RSLT;

              if (aCount === '-') return 1;
              if (bCount === '-') return -1;

              return aCount - bCount;
            })
          }
          else if(currSort.value == 'crCountAsc'){
            currSort.value = ''
            resetSort();
          }
          else{
            currSort.value = 'crCountDesc'
            searchRes.value.sort(function(a:any,b:any){
              const aCount = a.CREATININE.length === 0 ? '-' : a.CREATININE[a.CREATININE.length-1].CREATININE_RSLT;
              const bCount = b.CREATININE.length === 0 ? '-' : b.CREATININE[b.CREATININE.length -1].CREATININE_RSLT;

              if (aCount === '-') return 1;
              if (bCount === '-') return -1;

              return bCount - aCount;
            })
          }
          break;
      }
      setPage();
      usePageStore().initPage();
    }

    watch(() => patients.value, () => {
      searchPatient();
    });


    watch(() => currPage.value, () => {
      setPage();
    });

    onMounted(() => {
      searchPatient();

      const focusableDiv = document.querySelector('.focusable') as HTMLDivElement;
      const inputField = focusableDiv.querySelector('input') as HTMLInputElement;

      inputField.addEventListener('focus', () => {
        focusableDiv.classList.add('focused');
      });

      inputField.addEventListener('blur', () => {
        focusableDiv.classList.remove('focused');
      });
    });

    onUnmounted(() => {
      useIcuStore().togglePopup(false);
    });

    return {
      patients,
      searchRes,
      setPatientData,
      searchText,
      searchPatient,
      pageList,
      moment,
      sortPatient,
      currSort
    };
  },
});
