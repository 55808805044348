
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import Nav from "../components/Nav.vue";
import EditUserInfo from "../components/EditUserInfo.vue";
import Popup from "@/components/Popup.vue";
import { useUsersStore } from '@/store/users'
import { usePatientsStore } from '@/store/patients'
import { usePopupStore } from '@/store/popup'

export default defineComponent({
  components: {
    Nav,
    EditUserInfo,
    Popup
  },
  props:{
  },
  setup(props, {emit}) {
    const showEditUserInfoPage = computed(() => useUsersStore().getShowEditUserInfoPage);
    const getPatientsData = async (): Promise<void> => {
     await usePatientsStore().getPatientData();
    }
    const predictPopup = computed(() => usePopupStore().getTdmPredict);

    onMounted(() => {
      getPatientsData();
    });

    return {
      showEditUserInfoPage,
      predictPopup
    };
  },
});
