
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { useTdmStore } from '@/store/tdm'
import VueHighcharts from 'vue3-highcharts';

export default defineComponent({
  components: {
    VueHighcharts
  },
  props:{
  },
  setup(props, {emit}) {
    const predictRes = computed(() => useTdmStore().getPredictRes);
    const chartOptions = computed(()=> useTdmStore().getChartOptions);
    const options = ref();

    const initRes = () => {
      useTdmStore().initRes();
    }

    watch(() => chartOptions.value, (newVal) => {
      options.value = chartOptions.value;
    })

    onMounted(() => {
    });

    return {
      chartOptions,
      options,
      predictRes,
      initRes
    };
  },
});
