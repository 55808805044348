
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { usePopupStore } from '@/store/popup'
import { usePatientsStore } from '@/store/patients'

export default defineComponent({
  components: {
  },
  props:{
  },
  setup(props, {emit}) {
    const patientInfo = computed(() => usePatientsStore().getCurrPatient);
    const data = ref(patientInfo.value.data);

    const closePopup = () => {
      usePopupStore().setTdmPredict(false);
    }

    onMounted(() => {
    });

    return {
      closePopup,
      patientInfo,
      data
    };
  },
});
