import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d681309"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "monitoringchart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueHighcharts = _resolveComponent("VueHighcharts")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VueHighcharts, {
      type: "chart",
      options: _ctx.options,
      class: "chart-container",
      ref: "chartRef"
    }, null, 8, ["options"])
  ]))
}