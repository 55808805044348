
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { useTdmStore } from '@/store/tdm'

export default defineComponent({
  components: {
  },
  props:{
  },
  setup(props, {emit}) {
    const recomRes = computed(() => useTdmStore().getRecomRes);
    const showAllRes = ref(false);
    
    const initRes = () => {
      useTdmStore().initRes();
    }

    onMounted(() => {
    });

    return {
      recomRes,
      initRes,
      showAllRes
    };
  },
});
